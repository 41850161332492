window._ = require('lodash');

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    window.$ = window.jQuery = require('jquery');
    window.bootstrap = require('bootstrap');
    window.Popper = require('popper.js').default;

    require('bootstrap');
} catch (e) {
}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });

import {Fancybox} from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

try {
    window.Fancybox = Fancybox;
    Fancybox.bind(".page-hiw [data-fancybox]", {
        // Your custom options
    })
} catch (e) {
}


// collapse menu
try {
    $(window).on('resize', function(){
        $('#collapsingNavbarSm').collapse('hide');
    });
    $('#collapsingNavbarSm')
            .on('shown.bs.collapse', function () {
                console.log('shown.bs.collapse');
                const body = document.querySelector('body');
                body.style.overflow = "hidden";
            })
            .on('hidden.bs.collapse', function () {
                console.log('hidden.bs.collapse');
                const body = document.querySelector('body');
                body.style.overflow = "unset";
            })

} catch (e) {
}
