const register_toggle = () => {
    const register = document.getElementById('registerForm')
    const manage_products = document.getElementById('manage_products')

    if (!manage_products){
        return;
    }

    const upTo100 = document.getElementById('up_to_100')
    const upTo500 = document.getElementById('up_to_500')
    const moreThan500 = document.getElementById('more_than_500')

    manage_products.style.display = 'none'

    if (window.use_platform_for && window.use_platform_for === '$sell') {
        manage_products.style.display = 'block'
    }

    register.addEventListener('input', e => {

        if (e.target.value === 'sell') {
            manage_products.style.marginTop = '20px'
            manage_products.style.display = 'block'

        } else if (e.target.value === 'no_sell') {
            window.use_platform_for = ''
            upTo100.value = ''
            upTo500.value = ''
            moreThan500.value = ''
            manage_products.style.display = 'none'

        }

    })
}

register_toggle()