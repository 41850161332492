require('./flated');
require('./bootstrap');
require('./dropdown-menu');
require('./register');

import Analytics from 'analytics'
import googleTagManager from '@analytics/google-tag-manager'


if (process.env.MIX_APP_ENV == "production") {

  const analytics = Analytics({
    app: 'digital-catalogue',
    plugins: [
      googleTagManager({
        containerId: 'GTM-MQL87WRN'
      })
    ]
  })

  /* Track a page view */
  analytics.page()

}
