const main = function(){
    const MIN_RESOLUTION = 320;
    const MAX_RESOLUTION = 2000;
    const STEP_RESOLUTION = 20;

    // const headerEl = document.querySelector('header');
    const flatEl = document.querySelector('.flat');
    // const flatLogoEl = flatEl.querySelector('.flat-logo');
    const flatButtonEl = flatEl.querySelector('.flat-button');
    const flatMenuEl = flatEl.querySelector('.flat-menu');
    const flatMenuListEl = flatMenuEl.querySelector('.flat-menu > ul');

    let dataFlatPoint = parseInt(flatEl.getAttribute('data-flat-point').trim(), 10) || 992;
    //

    const initialize = function () {
        let initialWidth = MIN_RESOLUTION;

        // flatEl.style.visibility = 'hidden';
        flatEl.style.transition = 'unset';
        flatEl.classList.add('flat-out');

        for ( initialWidth = MIN_RESOLUTION ; initialWidth < MAX_RESOLUTION; initialWidth += STEP_RESOLUTION) {
            flatEl.style.width = initialWidth + 'px';
            // $(flatEl).css({ width: initialWidth});
            if (flatMenuEl.offsetWidth > flatMenuListEl.offsetWidth) { break; }
        }
        console.log(initialWidth, flatMenuEl.offsetWidth, flatMenuListEl.offsetWidth);
        flatEl.style.width = "100%";
        if(dataFlatPoint < initialWidth) {
            dataFlatPoint = initialWidth;
        }

        flatEl.style.visibility = 'visible';
        flatEl.classList.remove('flat-out');
    }
    //

    const hideScrollBody = function () {
        const body_el = document.querySelector('body');
        body_el.style.overflow = "hidden";
    }
    const showScrollBody = function () {
        const body_el = document.querySelector('body');
        body_el.style.overflow = "auto";
    }

    const menuShow = function () {
        flatButtonEl.classList.add('active');
        flatMenuEl.classList.add('show');
        hideScrollBody();
    }
    const menuHide = function () {
        flatButtonEl.classList.remove('active');
        flatMenuEl.classList.remove('show');
        showScrollBody();
    }
    const menuToggle = function () {
        if (flatMenuEl.classList.contains('show')) {
            menuHide()
        } else {
            menuShow();
        }
    }

    const handlerResize = (event) => {
        const is_under_flat_point = window.innerWidth < dataFlatPoint;

        if (is_under_flat_point) {
            flatEl.classList.remove('flat-out');
            flatEl.classList.add('flat-in');
            menuHide();
        } else {
            flatEl.classList.remove('flat-in');
            flatEl.classList.add('flat-out');
        }

    };
    //
    initialize();
    handlerResize();
    window.addEventListener('resize', handlerResize);
    //
    window.toggleFlatMenu = function(){
        menuToggle();
    }
}

const target_compare = document.querySelector('.compare-plans')
const target_plans = document.querySelector('.plans')
const target_compare_plans_nodes = document.querySelectorAll('.compare-plans tbody tr td:not(.information-left)')
const sticky_header = document.querySelector('.stick-top-mobile')

if (target_compare || target_plans || sticky_header) {

    function stickyTableHeadForMobile() {
        const target_bound = sticky_header.getBoundingClientRect()
        
        window.addEventListener('scroll', () => {
            if (window.matchMedia("(max-width: 768px)").matches) {

                if (window.scrollY > target_bound.top - target_bound.height) {
                    sticky_header.style.transform = 'translate3d(0, ' + ((window.scrollY - target_bound.y) + 56) + 'px, 0)';
                } else {
                    sticky_header.style.transform = 'translate3d(0,0,0)';
                }

            } else {
                sticky_header.style.transform = 'unset'
            }
        })
    }

    function initComparePlansAnimate() {

        if (target_compare_plans_nodes.length) {
            if(window.matchMedia("(max-width: 768px)").matches) {
                target_compare_plans_nodes.forEach(node => {
                    node.classList.add('slideAnimation')
                })
            } else {
                target_compare_plans_nodes.forEach(node => {
                    node.classList.remove('slideAnimation')
                })
            }
        }
    }

    function cancelComparePlansOnSwipe() {
    
        if (target_compare_plans_nodes.length) {
            target_compare_plans_nodes.forEach(node => {
                if (node.classList.contains('slideAnimation')) {
                    node.classList.remove('slideAnimation')
                }
            })
        }
    }

    const cancelPlansAnimationOnSwipe = () => target_plans.classList.remove('slideAnimation')

    let touch_start_plans_x = null
    target_plans.addEventListener('touchstart', (e) => {
        touch_start_plans_x = e.targetTouches[0].pageX
    })
    target_plans.addEventListener('touchmove', (e) => {
        if (e.targetTouches[0].pageX > (touch_start_plans_x + 100) || e.targetTouches[0].pageX < (touch_start_plans_x - 100)) {
            cancelPlansAnimationOnSwipe()
        }
    })

    let touch_start_compare_x = null
    target_compare.addEventListener('touchstart', (e) => {
        touch_start_compare_x = e.targetTouches[0].pageX
    })
    target_compare.addEventListener('touchmove', (e) => {
        if (e.targetTouches[0].pageX > (touch_start_compare_x + 100) || e.targetTouches[0].pageX < (touch_start_compare_x - 100)) {
            cancelComparePlansOnSwipe()
        }
    })

    stickyTableHeadForMobile()
    initComparePlansAnimate()

    window.addEventListener('resize', () => {
        // stickyTableHeadForMobile()
        initComparePlansAnimate()
    })
}

// window.addEventListener('DOMContentLoaded', main);
main();




