(function(){
    $.each($('.custom-dropdown-menu'), function(idx, item){
        $(item).on('click', function(){
            $(item).toggleClass('show');
        });
    });

    window.addEventListener('click', function (event) {
        const element = event.target;
        $.each($('.custom-dropdown-menu'), function(idx, item){
            const selector = '#'+ item.getAttribute('id');
            if (!element.closest(selector)) {
                $(selector).removeClass('show');
            }
        })
    });

    window.addEventListener('scroll', function (event) {
        $('.custom-dropdown-menu').removeClass('show');
    });
})()
