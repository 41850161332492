const main = function(){
    const MIN_RESOLUTION = 320;
    const MAX_RESOLUTION = 2000;
    const STEP_RESOLUTION = 20;

    // const headerEl = document.querySelector('header');
    const flatEl = document.querySelector('.flat');
    // const flatLogoEl = flatEl.querySelector('.flat-logo');
    const flatButtonEl = flatEl.querySelector('.flat-button');
    const flatMenuEl = flatEl.querySelector('.flat-menu');
    const flatMenuListEl = flatMenuEl.querySelector('.flat-menu > ul');

    let dataFlatPoint = parseInt(flatEl.getAttribute('data-flat-point').trim(), 10) || 992;
    //

    const initialize = function () {
        let initialWidth = MIN_RESOLUTION;

        // flatEl.style.visibility = 'hidden';
        flatEl.style.transition = 'unset';
        flatEl.classList.add('flat-out');

        for ( initialWidth = MIN_RESOLUTION ; initialWidth < MAX_RESOLUTION; initialWidth += STEP_RESOLUTION) {
            flatEl.style.width = initialWidth + 'px';
            // $(flatEl).css({ width: initialWidth});
            if (flatMenuEl.offsetWidth > flatMenuListEl.offsetWidth) { break; }
        }
        console.log(initialWidth, flatMenuEl.offsetWidth, flatMenuListEl.offsetWidth);
        flatEl.style.width = "100%";
        if(dataFlatPoint < initialWidth) {
            dataFlatPoint = initialWidth;
        }

        flatEl.style.visibility = 'visible';
        flatEl.classList.remove('flat-out');
    }
    //

    const hideScrollBody = function () {
        const body_el = document.querySelector('body');
        body_el.style.overflow = "hidden";
    }
    const showScrollBody = function () {
        const body_el = document.querySelector('body');
        body_el.style.overflow = "auto";
    }

    const menuShow = function () {
        flatButtonEl.classList.add('active');
        flatMenuEl.classList.add('show');
        hideScrollBody();
    }
    const menuHide = function () {
        flatButtonEl.classList.remove('active');
        flatMenuEl.classList.remove('show');
        showScrollBody();
    }
    const menuToggle = function () {
        if (flatMenuEl.classList.contains('show')) {
            menuHide()
        } else {
            menuShow();
        }
    }

    const handlerResize = (event) => {
        const is_under_flat_point = window.innerWidth < dataFlatPoint;

        if (is_under_flat_point) {
            flatEl.classList.remove('flat-out');
            flatEl.classList.add('flat-in');
            menuHide();
        } else {
            flatEl.classList.remove('flat-in');
            flatEl.classList.add('flat-out');
        }

    };
    //
    initialize();
    handlerResize();
    window.addEventListener('resize', handlerResize);
    //
    window.toggleFlatMenu = function(){
        menuToggle();
    }
}

// window.addEventListener('DOMContentLoaded', main);
main();




